import users from './useritem.js';
import { useHistory } from "react-router-dom";
import React, { useState } from 'react';



const Keptar = () => {
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredUsers = users.filter(user => user.name.toLowerCase().includes(searchQuery.toLowerCase()) || user.birthdate.includes(searchQuery))

    const handleClick = (value) => {
        history.push('/profile');
        localStorage.setItem('username', value);
    };
  return (
    <div style={{ textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff", height: "85vh", overflow: "auto", flexDirection: "column" }}>
        <input style={{ boxShadow: "0px 0px 20px -18px", margin: "10px", width: "20vw", height: "30px", borderRadius: "10px", border: "none", backgroundColor: "#e8e8e8", padding: "10px", fontSize: "0.8em", fontWeight: "bold",}} type="text" placeholder="KERESÉS" value={searchQuery} onChange={handleSearchInputChange} />
        <ul className="monthlistkeptar">
        {filteredUsers.map((item, index) => (
            <li onClick={() => handleClick(item.name)} style={{display: "inline-block", margin: '5px'}} key={index}>
              <img alt="" className="imageslimkeptar" src={require("../assets/man.jpeg")}></img>
            </li>
          ))}
        </ul>
      
    </div>
  );
};

export default Keptar;
