import '../App.css';
import items from './monthitem.js';
import users from './useritem.js';
import { useHistory } from "react-router-dom";
import React from 'react';

function Fooldal() {

  const history = useHistory();

  
  const handleClick = (value) => {
    history.push('/month');
    localStorage.setItem('myKey', JSON.stringify(value));
  };


  const List = (props) => (
    <ul className="monthlist">
      {props.items.map((item, index) => (
        <li className="listitem" onClick={() => handleClick(item.id)} key={index}>
          <div className="itemtitle">
            {item.name}
          </div>
          <ul className="imagesul">
          {users.filter(user => new Date(user.birthdate).getMonth() === item.id).map((item, index) => (
            <li style={{display: "inline-block", margin: '5px'}} key={index}>
              <img alt="" className="imageslim" src={require('../assets/man.jpeg')}></img>
            </li>
          ))}
          </ul>
        </li>
      ))}
    </ul>
  );
  
  return (
    <div className="App">
      <div className="months">
        <List items={items} />
      </div>
    </div>
  );
}

export default Fooldal;
