const users = [
    {
      "name": "Adrian Perez",
      "birthdate": "1997-08-03",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Ashley Smith",
      "birthdate": "1994-02-10",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Benjamin Lee",
      "birthdate": "1990-02-16",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Brittany Davis",
      "birthdate": "1995-02-16",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Carlos Garcia",
      "birthdate": "1988-02-16",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Chelsea Johnson",
      "birthdate": "1999-02-16",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Christian Perez",
      "birthdate": "2000-10-08",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Christina Thomas",
      "birthdate": "1991-02-16",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Christopher Davis",
      "birthdate": "1985-09-14",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Cindy Brown",
      "birthdate": "1996-03-24",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Cynthia Rodriguez",
      "birthdate": "1989-07-26",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Daniel Martinez",
      "birthdate": "1992-01-18",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "David Nguyen",
      "birthdate": "1994-05-12",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Deborah Harris",
      "birthdate": "1987-11-06",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Denise Wilson",
      "birthdate": "1998-08-23",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Destiny Jackson",
      "birthdate": "1991-12-30",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Edward Perez",
      "birthdate": "1992-06-20",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Elizabeth Wilson",
      "birthdate": "1993-09-15",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Emily Lee",
      "birthdate": "1990-04-01",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Erica Lewis",
      "ceg": "Swietelsky",
      "sekcio": "marketing",
      "email": "exapmle@example.com",
      "birthdate": "1997-07-31",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Erika Perez",
      "birthdate": "1986-01-07",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Gabriel Martinez",
      "birthdate": "1999-02-25",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "George Johnson",
      "birthdate": "1992-11-29",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Grace Hernandez",
      "birthdate": "1991-08-21",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Hannah Davis",
      "birthdate": "1994-03-19",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Isabella Martinez",
      "birthdate": "1998-10-12",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Jack Brown",
      "birthdate": "1988-05-13",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Jacob Taylor",
      "birthdate": "1993-07-08",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "James Davis",
      "birthdate": "1991-10-10",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Jason Harris",
      "birthdate": "1985-08-06",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Jennifer Davis",
    "birthdate": "1996-06-17",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Jessica Jones",
    "birthdate": "1989-02-09",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "John Wilson",
    "birthdate": "1995-11-25",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Jonathan Davis",
    "birthdate": "1992-04-02",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Jordan Perez",
    "birthdate": "1998-08-05",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Joseph Lee",
    "birthdate": "1987-12-18",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Joshua Smith",
    "birthdate": "1990-09-27",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Julia Hernandez",
    "birthdate": "1996-03-28",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Julian Rodriguez",
    "birthdate": "1999-06-29",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Justin Nguyen",
    "birthdate": "1991-01-14",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Karen Brown",
    "birthdate": "1993-05-09",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Katherine Lewis",
    "birthdate": "1994-10-23",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Kelsey Taylor",
    "birthdate": "1995-09-11",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Kevin Johnson",
    "birthdate": "1990-02-04",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Kimberly Davis",
    "birthdate": "1996-11-07",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Kyle Wilson",
    "birthdate": "1986-12-28",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Lauren Perez",
    "birthdate": "1992-08-13",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Liam Garcia",
    "birthdate": "1999-01-05",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Linda Brown",
    "birthdate": "1993-04-12",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Lisa Hernandez",
    "birthdate": "1985-10-22",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Logan Davis",
    "birthdate": "1996-06-18",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Lucas Johnson",
    "birthdate": "1998-09-21",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Madison Martinez",
    "birthdate": "1991-11-30",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Makayla Davis",
    "birthdate": "1997-02-16",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Maria Perez",
    "birthdate": "1994-02-16",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Mason Lee",
    "birthdate": "1990-02-26",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Matthew Wilson",
    "birthdate": "1989-03-03",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Megan Garcia",
    "birthdate": "1992-12-04",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Melanie Brown",
    "birthdate": "1995-01-20",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Michael Johnson",
    "birthdate": "1993-08-17",
    "img": "../assets/man.jpeg"
    },
    {
    "name": "Michelle Davis",
    "birthdate": "1988-06-14",
    "img": "../assets/man.jpeg"
    },
    {
      "name": "Mia Rodriguez",
      "birthdate": "1989-06-27",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "William Davis",
      "birthdate": "1992-09-18",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Avery Wilson",
      "birthdate": "1998-04-05",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Ethan Perez",
      "birthdate": "1995-01-23",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Emma Martin",
      "birthdate": "1991-11-09",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Nathan Thompson",
      "birthdate": "1997-03-14",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Chloe Baker",
      "birthdate": "1994-05-12",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Oliver White",
      "birthdate": "1993-12-07",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Ava Harris",
      "birthdate": "1990-08-01",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Jackson Clark",
      "birthdate": "1998-11-26",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Carla Lopez",
      "birthdate": "1993-12-15",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Daniel Kim",
      "birthdate": "1998-01-20",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Emily Chen",
      "birthdate": "1995-04-23",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Franklin Rodriguez",
      "birthdate": "1991-07-11",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Gina Chen",
      "birthdate": "1989-09-09",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Harry Park",
      "birthdate": "1987-11-24",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Isabella Lee",
      "birthdate": "1992-03-14",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Jacob Martinez",
      "birthdate": "1994-09-02",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Karen Wong",
      "birthdate": "1997-05-22",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Luis Hernandez",
      "birthdate": "1990-12-06",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Melissa Johnson",
      "birthdate": "1993-06-29",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Nicholas Lee",
      "birthdate": "1988-10-05",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Olivia Kim",
      "birthdate": "1996-01-09",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Paula Garcia",
      "birthdate": "1989-02-18",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Quentin Martin",
      "birthdate": "1991-03-07",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Renee Lee",
      "birthdate": "1994-05-12",
      "img": "../assets/woman.jpeg"
    },
    {
      "name": "Samuel Gonzalez",
      "birthdate": "1987-08-21",
      "img": "../assets/man.jpeg"
    },
    {
      "name": "Tiffany Wang",
      "birthdate": "1996-10-28",
      "img": "../assets/woman.jpeg"
    },
    
    
];

export default users;