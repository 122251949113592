import '../App.css';
import React, { useState } from 'react';

function Admin() {
const [image, setImage] = useState(null);

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    const imageBlob = new Blob([selectedFile], { type: selectedFile.type });

    // Convert the blob to a data URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(imageBlob);
  };

  return (
    <div className="App">
      <div className='input-container'>
        <div className="input">
            <div style={{width: '100%', flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <label style={{marginBottom: "50px", fontWeight: "bold", fontSize: "2em"}} htmlFor="image-upload">Válassz egy fényképet:</label>
                <input style={{}} type="file" id="image-upload" accept="image/*" onChange={handleImageUpload} />
                {image && <img src={image} alt="" />}

            </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
