import '../App.css';
import 'react-calendar/dist/Calendar.css';
import Calendar from './Calendar.js';
import 'react-big-calendar/lib/css/react-big-calendar.css';

function Fooldal() {

  return (
    <div className="App">
      <div className='calendar-container'>
        <Calendar />
      </div>
    </div>
  );
}

export default Fooldal;
