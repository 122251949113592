import '../App.css';
import React from 'react';
import users from './useritem.js';
import { useHistory } from "react-router-dom";

function CalendarDay({ date, users }) {
    // Filter the list of users to find those whose birthday is on this date
    const usersOnThisDay = users.filter(user => {
      const userBirthday = new Date(user.birthdate);
      
      return userBirthday.getMonth() === date.getMonth() && userBirthday.getDate() === date.getDate();
      
    });

    const history = useHistory();

    const handleClick = (value) => {
      history.push('/profile');
      localStorage.setItem('username', value);
    };
  
    // Render the list of users for this date
    return (
      <div className="calendar-day">
        <div className="calendar-day-date">{date.getDate()}</div>
        <ul className="calendar-day-user">
          {usersOnThisDay.map(user => (
            <li onClick={() => handleClick(user.name)} style={{display: "inline-block"}} key={user.name}>
                {/* TODO src require from database */}
                <img className="imageslim" alt="" src={require('../assets/man.jpeg')}></img>
            </li>
          ))}
        </ul>
      </div>
    );
  }



class Calendar extends React.Component {
    constructor(props) {
      super(props);
      const savedItem = localStorage.getItem("myKey");
      const parsedItem = JSON.parse(savedItem);
      this.state = {
        month: parsedItem // start with the current month
      };

    }
  
    render() {
      const { month } = this.state;
      const daysInMonth = new Date(new Date().getFullYear(), month + 1, 0).getDate(); // get the number of days in the selected month
      
      // Create an array of dates for the selected month
      const dates = Array.from({ length: daysInMonth }, (_, i) => new Date(new Date().getFullYear(), month, i + 1));

      // eslint-disable-next-line
      const usersCount = users.reduce((count, value) => (new Date(value.birthdate).getMonth() == month ? count + 1 : count), 0)
      
      return (
        <div className="calendar">
          <div className="calendar-header">{dates[0].toLocaleString('default', { month: 'long', year: 'numeric' }) + "i dolgozók: " + usersCount}</div>
          <div className="calendar-grid">
            {dates.map(date => (
              <CalendarDay key={date} date={date} users={users} />
            ))}
          </div>
        </div>
      );
    }
  }
  

  export default Calendar;