const items = [
    { id: 0, name: 'JANUÁR' },
    { id: 1, name: 'FEBRUÁR' },
    { id: 2, name: 'MÁRCIUS' },
    { id: 3, name: 'ÁPRILIS' },
    { id: 4, name: 'MÁJUS' },
    { id: 5, name: 'JÚNIUS' },
    { id: 6, name: 'JÚLIUS' },
    { id: 7, name: 'AUGUSZTUS' },
    { id: 8, name: 'SZEPTEMBER' },
    { id: 9, name: 'OKTÓBER' },
    { id: 10, name: 'NOVEMBER' },
    { id: 11, name: 'DECEMBER' }
  ];

  
  export default items;