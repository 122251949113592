import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Fooldal from "./pages/Fooldal";
import Month from "./pages/Month";
import Keptar from "./pages/Keptar";
import Profile from "./pages/Profile";
import Admin from "./pages/Admin";
import { Link } from 'react-router-dom';

import "./App.css";

function App() {
  return (
    <Router>
      <header className="App-header">
        <p style={{fontWeight: "bold", fontSize: "2em", position: "absolute", left: "20px", margin: "0px"}}>SWIETELSKY</p>
        <ul style={{ listStyleType: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0'}}>
          <li className="headerlist"><Link style={{textDecoration: "none", color: "#006DB8"}} to="/">FŐOLDAL</Link></li>
          <li className="headerlist"><Link style={{textDecoration: "none", color: "#006DB8"}} to="/keptar">KÉPTÁR</Link></li>
          <li className="headerlist"><Link style={{textDecoration: "none", color: "#006DB8"}} to="/admin">ADMIN</Link></li>
        </ul>
      </header>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Fooldal />
          </Route>
          <Route exact path="/month">
            <Month />
          </Route>
          <Route exact path="/admin">
            <Admin />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/keptar">
            <Keptar />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
