import React from 'react';
import users from './useritem.js';

const Profile = () => {

    const name = localStorage.getItem('username');
    const birthdate = users.filter(user => user.name === name).map(item => item.birthdate);
    const ceg = users.filter(user => user.name === name).map(item => item.ceg);
    const sekcio = users.filter(user => user.name === name).map(item => item.sekcio);
    const email = users.filter(user => user.name === name).map(item => item.email);
    const month = users.filter(user => user.name === name).map(item => new Date(item.birthdate).getMonth() + 1);
    console.log(month);
  return (
    <div style={{ textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff", height: "85vh" }}>

        <div style={{ alignItems: "center", justifyContent: "center", width: "50%", height: "50vh" , backgroundColor: "#006DB8", padding: "10px", borderRadius: "20px",display: "flex", flexDirection: "row",}}>
            <div>
                <img alt="" style={{width: "240px", height: "180px", borderRadius: "20px"}} src={require('../assets/man.jpeg')}></img>

            </div>

            <div style={{ display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "5em" }}>
                <p className="pe">{name}</p>
                <p className="ped">{birthdate}</p>
                <p className="ped">{ceg}</p>
                <p className="ped">{sekcio}</p>
                <p className="ped">{email}</p>
            </div>

        </div>
        

      
    </div>
  );
};

export default Profile;
